@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');


::-webkit-scrollbar {
  display: none;
}

body{
  overflow: hidden;
  justify-content: center;
  
}
.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.25vw;
  color: #00404f;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes shake {
   0%, 100% {transform: translateX(0);} 
   10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);} 
   20%, 40%, 60%, 80% {transform: translateX(10px);} 
} 
