@keyframes fade-down-in {
  0% {
    transform: translateY(-300px);
    opacity: 0%;
  }
  100% {
    transform: translateY(0px);
    opacity: 100%;
  }
}

@keyframes fade-down-out {
  0% {
    transform: translateY(0px);
    opacity: 100%;
  }
  100% {
    transform: translateY(300px);
    opacity: 0%;
  }
}